import {FeatureStoreConfig} from '../../types/feature-store-config.type';
import {FormGroupEffects, formGroupReducer, FormGroupStore} from '@cycles/data-access';
import {FeatureStoreNames} from '@shared-types';
import {featureStoresInitialState} from '../feature-stores.initial-state';
import IFormGroupsState = FormGroupStore.IFormGroupsState;

export const formGroupFeatureStoreConfig: FeatureStoreConfig<IFormGroupsState> = {
	key: FeatureStoreNames.FORM_GROUP_FEATURE_KEY,
	initialState: featureStoresInitialState[FeatureStoreNames.FORM_GROUP_FEATURE_KEY] as IFormGroupsState,
	effects: [FormGroupEffects],
	reducer: formGroupReducer,

	onAppInit(state: IFormGroupsState): IFormGroupsState {
		return state;
	},
	onLoginFn(state: IFormGroupsState): IFormGroupsState {
		return state;
	},
	onLogout(state: IFormGroupsState): IFormGroupsState {
		return this.initialState;
	},
};
