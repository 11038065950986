import {HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, RouterFeatures, withComponentInputBinding, withDebugTracing, withEnabledBlockingInitialNavigation, withViewTransitions} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {environment} from '@shared-types';
import {appRoutes} from '../app.routes';
import {HttpLoaderFactory} from '../factories/translate-http-loader-factory';
import {ExternalModules} from './app-ext-modules.config';
import AppProviders from './app-providers.config';
import {TokenInterceptor} from '@auth/data-access';
import {Shared} from '@app-store-management';
import APP_STORE_PROVIDERS = Shared.APP_STORE_PROVIDERS;

/**
 * @warn don't change the order of providers
 */
export const appConfig: () => ApplicationConfig = () => {
	const routerProviderFeatures: RouterFeatures[] = [withEnabledBlockingInitialNavigation(), withComponentInputBinding(), withViewTransitions()];
	if (environment.CONFIG['DevTools']['debugRouterTracing']) routerProviderFeatures.push(withDebugTracing());

	return {
		providers: [
			...AppProviders,
			importProvidersFrom([
				TranslateModule.forRoot({
					loader: {
						provide: TranslateLoader,
						useFactory: HttpLoaderFactory,
						deps: [HttpClient],
					},
				}),
				...ExternalModules,
			]),
			...APP_STORE_PROVIDERS,

			provideRouter(appRoutes, ...routerProviderFeatures),
			provideHttpClient(withInterceptors([TokenInterceptor()]), withInterceptorsFromDi()),
			provideAnimations(),
		],
	};
};
