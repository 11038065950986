import {Injector, signal} from '@angular/core';
import {LanguageService} from '@core/language';
import {StorageService} from '@core/storage';

import {environment, isDevMode, LanguageType, setMainInjector} from '@shared-types';
import {DeviceService} from '@shared/device';
import {SyncableProcessLifeCycle, SyncerService} from '@utils';
import {PrimeNGConfig} from 'primeng/api';
import {GlobalsService} from '@global-data';
import clc from 'console-log-colors';

export function AppInitializer(injector: Injector, storageService: StorageService, languageService: LanguageService, deviceService: DeviceService, primeNGConfig: PrimeNGConfig, syncerService: SyncerService, globalService: GlobalsService) {
	return async () => {
		if (isDevMode()) {
			const currentLang: LanguageType = languageService.currentLang$();
			const deviceType: string = deviceService.deviceType();
			console.group(clc.cyan('------------------ Info	------------------'));
			console.info(clc.cyan('Current Language:'), currentLang);
			console.info(clc.cyan('Device Type:'), deviceType);
			console.info(clc.cyan('Environment:'), environment);
			console.groupEnd();
		}
		setMainInjector(injector);
		// if (_RouteChunksService) await initRouteChunks(_RouteChunksService)();
		storageService.init();
		environment.deviceType = deviceService.deviceType();
		// primeNGConfig:
		initPrimeNgConfig(primeNGConfig);
		// syncerService - trigger AppInit lifeCycle processes:
		syncerService.checkProcesses(SyncableProcessLifeCycle.AppInit);
	};
}

function initPrimeNgConfig(primeNGConfig: PrimeNGConfig): void {
	// input style:
	primeNGConfig.inputStyle = signal('outlined');
}
