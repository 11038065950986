import {FeatureStoreConfig} from '../../types/feature-store-config.type';
import {GroupsEffects, groupsReducer, GroupsStore} from '@users/data-access';
import {FeatureStoreNames} from '@shared-types';
import {featureStoresInitialState} from '../feature-stores.initial-state';
import IGroupsState = GroupsStore.IGroupsState;

export const groupsFeatureStoreConfig: FeatureStoreConfig<IGroupsState> = {
	key: FeatureStoreNames.GROUPS_FEATURE_KEY,
	initialState: featureStoresInitialState[FeatureStoreNames.GROUPS_FEATURE_KEY] as IGroupsState,
	effects: [GroupsEffects],
	reducer: groupsReducer,

	onAppInit(state: IGroupsState): IGroupsState {
		return state;
	},
	onLoginFn(state: IGroupsState): IGroupsState {
		return state;
	},
	onLogout(state: IGroupsState): IGroupsState {
		return this.initialState;
	},
};
