import {FeatureStoreSchema, IFeatureStoresSchema} from '../types';
import {BaseStoreConfig} from '../../shared/base-store-config.type';

import * as CONFIGS from './feature-store-configurations/index';
import {FeatureStoreNames} from '@shared-types';

export namespace FeatureStoreConfigs {
	export type FeatureStoreConfigsStruct<AppStoreSchema extends IFeatureStoresSchema = IFeatureStoresSchema, FeatureName extends keyof AppStoreSchema = keyof AppStoreSchema> = Partial<Record<FeatureName, BaseStoreConfig<AppStoreSchema[FeatureName] & FeatureStoreSchema>>>;

	export const featureStoreConfigs: FeatureStoreConfigsStruct = {
		/*	---------------------------------------------  Feature Stores --------------------------------------------- */
		[FeatureStoreNames.APP_INFO_FEATURE_KEY]: undefined,
		[FeatureStoreNames.AUTH_FEATURE_KEY]: undefined,
		[FeatureStoreNames.CYCLE_SIGN_FEATURE_KEY]: undefined,
		[FeatureStoreNames.FORM_GROUP_FEATURE_KEY]: undefined,
		[FeatureStoreNames.USERS_FEATURE_KEY]: undefined,
		[FeatureStoreNames.GLOBAL_SEARCH_FEATURE_KEY]: undefined,
		[FeatureStoreNames.COMPANY_SETTINGS_FEATURE_KEY]: undefined,
		[FeatureStoreNames.META_TAGS_FEATURE_KEY]: undefined,
		[FeatureStoreNames.GROUPS_FEATURE_KEY]: undefined,
	};

	Object.values(CONFIGS).forEach(config => {
		if (config.key in featureStoreConfigs) {
			featureStoreConfigs[config.key] = config;
		}
	});
}
