import IGNORE_ERRORS from './constants/error-ignore.json';
import {ErrorHandler, inject, Injectable, Injector} from '@angular/core';
import clc from 'console-log-colors';
import {ToastConfig, ToastService} from '@utils';
import {Toast} from 'primeng/toast';
import {ApiError, isApiError} from '@auth/data-access';
import {VersionManagementService} from '@pwa';
import {captureException} from '@sentry/angular';
import {RouteHistoryService} from '@global-data';
import {Router} from '@angular/router';

const ERRORS_TO_IGNORE: string[] = IGNORE_ERRORS.IGNORE_ERRORS;

@Injectable({
	providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
	private readonly versionManagementService: VersionManagementService = inject(VersionManagementService);
	protected readonly injector: Injector = inject(Injector);
	private readonly toastService: ToastService = inject(ToastService);
	private readonly routeHistoryService: RouteHistoryService = inject(RouteHistoryService);
	private readonly router: Router = inject(Router);

	constructor() {}

	async handleError(error: any): Promise<void> {
		if (this.isErrorIgnored(error)) {
			return;
		}
		console.error(clc.red('[Global Error Handler]'), error);
		if (this.isFetchModuleError(error)) {
			const lastRoute = this.routeHistoryService.getRouteHistory().find(route => route && route !== this.router.url) || '/';
			debugger;
			const errorInfo = {
				error,
				timestamp: new Date().toISOString(),
			};
			localStorage.setItem('FetchModuleError', JSON.stringify(errorInfo));
			await this.versionManagementService.checkForUpdates();
			setTimeout(() => {
				window.location.href = `${window.location.origin}${lastRoute}`;
			}, 1000);
			return;
		}
		debugger;

		if (isApiError(error)) {
			this.showErrorToast(error);
		} else {
			captureException(error);
		}
	}

	private isErrorIgnored(error: any): boolean {
		return ERRORS_TO_IGNORE.some((errorToIgnore: string) => error?.message?.includes(errorToIgnore));
	}

	private isFetchModuleError(error: any): boolean {
		return error instanceof Error && error.message.includes('Failed to fetch dynamically imported module');
	}

	private showErrorToast(apiError: ApiError, errorDetailsPrefixLabel: string = window.translate('dc-error.error-details')): void {
		const {apiErrorObject} = apiError;
		const toast: ToastConfig = {
			message: window.translate('dc-error.default-message'),
			subMessage: `${errorDetailsPrefixLabel} ${apiErrorObject?.userMessage ? window.translate(apiErrorObject.userMessage) : apiError?.name}`,
			severity: 'error',
			type: 'error',
			sticky: true,
		};

		toast.actionButton = {
			action: function (config: ToastConfig, appToast: Toast, injector: Injector): void {
				debugger;
				// injector.get(SentryService).showErrorReportDialog(apiError);
			},
			autoCloseOnAction: true,
			label: window.translate('dc-error.report.report-btn'),
			icon: 'fa-duotone fa-exclamation-circle',
		};

		this.toastService.open(toast);
	}
}
