import { MetaReducer, Action } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import {StoreGlobalActionType} from '@global-data';

export const globalErrorMetaReducer: MetaReducer<any> = (reducer: ActionReducer<any>): ActionReducer<any> => {
	return function (state, action) {
		if (action.type === StoreGlobalActionType.GLOBAL_ERROR) {
			debugger;
			const nextState = {...state};
			return nextState;
		}

		return reducer(state, action);
	};
};
