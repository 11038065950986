import { EnvironmentName } from '@shared-types';

// https://doccycle.{env}/{module}/{company}/{params}
function checkCompanyInParams(currentUrl: string, allowedModules: string[] = ['sign']): boolean {
	const regex = /^https:\/\/doccycle\.(dev|xyz|biz|net)\/([\w-]+)\/([\w-]+)\/(.*)$/;

	const match: RegExpMatchArray = currentUrl.match(regex);

	if (match) {
		const environment = match[1];
		const module = match[2];
		const company = match[3];
		const params = match[4];
		if (Object.values(EnvironmentName).some(env => env === environment) && company && allowedModules.includes(module)) {
			window.location.href = `https://${company}.doccycle.${environment}/${module}/${params}`;
			return false; // Redirected
		}
	}

	return true; // Not redirected
}

// The following function check if the URL is empty (without '/'	at the end, e.g. 'https://doccycle.dev') and force redirect to 'https://doccycle.dev/'.
function checkEmptyUrl(currentUrl: string): boolean {
	// the pattern: {protocol}://{host} without '/' at the end (protocol: https or http, host: [a-zA-Z0-9.-] or localhost:{port})
	const pattern = /^(https?:\/\/)([a-zA-Z0-9.-]+|localhost(:\d+)?)$/;
	if (pattern.test(currentUrl)) {
		window.location.href = `${currentUrl}/`;
		return false; // Redirected
	}
	return true; // Not redirected
}

/**
 * @description Redirector that checks the URL and redirects if necessary
 * @note This function is used to redirect the user to the correct URL.
 * @dev If you need to add more checks, add them to the CheckFns array and they will be executed in order.
 * @param {void}
 * @returns {boolean} - Returns true if the URL is correct, false if the URL is incorrect and the user is redirected
 */
export function routeRedirector(): boolean {
	const currentUrl: string = window.location.href;
	const checkFns: ((url: string) => boolean)[] = [checkEmptyUrl, checkCompanyInParams];
	return checkFns.every(checkFn => checkFn(currentUrl));
}
