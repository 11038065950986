import {AuthGuard, isDevGuard, LoginMatcher, PermissionsGuard, PWAGuard, RedirectGuard} from '@auth/data-access';
import {PermissionType, ValidationType} from '@core/api/data-access';
import {GlobalAppRoute, globalAppRouteConfigFactory} from '@global-data';
import {GlobalDialoglService} from '@shared/dialog';
import {canActivateValidation, onFailValidationNavTo} from '@validations/data-access';

// new
export const appRoutes: GlobalAppRoute[] = [
	{
		path: '',
		canActivate: [RedirectGuard],
		children: [
			{
				path: 'login',
				canMatch: [LoginMatcher()],
				loadChildren: () => import('@auth/feature-auth').then(m => m.authFeatureAuthRoutes),
			},
			{
				path: 'build-in-forms',
				loadChildren: () => import('@forms/build-in-forms').then(m => m.featureBuildInFormsRoutes),
			},
			{
				path: 'sign',
				loadChildren: () => import('@cycles/sign/feature-sign').then(m => m.featureSignRoutes),
			},
			{
				path: 'fast-sign',
				loadChildren: () => import('@cycles/sign/fast-signing').then(m => m.featureFastSigningRoutes),
			},
			{
				path: 'validations',
				canActivate: [AuthGuard],
				children: [
					{
						path: 'liveness',
						canActivate: [canActivateValidation(ValidationType.Liveness, onFailValidationNavTo)],
						loadChildren: () => import('@validations/liveness/feature-liveness').then(m => m.featureLivenessRoutes),
						data: globalAppRouteConfigFactory({autoHideSidenav: true}),
					},
					{
						path: 'face-match',
						canActivate: [canActivateValidation(ValidationType.FaceMatch, onFailValidationNavTo)],
						loadChildren: () => import('@validations/face-match/feature-face-match').then(m => m.featureFaceMatchRoutes),
						data: globalAppRouteConfigFactory({autoHideSidenav: true}),
					},
					{
						path: 'document-reader',
						canActivate: [canActivateValidation(ValidationType.DocumentVerification, onFailValidationNavTo)],
						loadChildren: () => import('@validations/document-reader/feature-document-reader').then(m => m.featureDocumentReaderRoutes),
						data: globalAppRouteConfigFactory({autoHideSidenav: true}),
					},
				],
			},

			{
				path: 'cw',
				canActivate: [PermissionsGuard([PermissionType.cycle_create])],
				loadChildren: () => import('@cycles/creation-wizard/feature-creation-wizard-main').then(m => m.featureCreationWizardRoutes),
			},
			{
				path: 'home',
				canActivate: [AuthGuard, PermissionsGuard([PermissionType.permanent], {fallbackLogout: true}), PWAGuard ],
				loadChildren: () => import('@app-layout/home/feature-home-overview').then(m => m.featureHomeOverviewRoutes),
			},
			{
				path: 'global-search',
				loadChildren: () => import('@app-layout/global-search/feature-global-search').then(m => m.featureGlobalSearchRoutes),
			},
			{
				path: 'swagger',
				canActivate: [PermissionsGuard([PermissionType.admin], {fallbackRoute: 'home'})],
				loadChildren: () => import('@dev-tools/swagger').then(m => m.featureSwaggerRoutes),
			},
			{
				path: 'dev-panel',
				canActivate: [PermissionsGuard([PermissionType.permanent, PermissionType.users_manage], {fallbackRoute: 'home'})],
				loadChildren: () => import('@dev-tools/panel').then(m => m.featureDevPanelRoutes),
			},
			{
				path: 'status',
				loadComponent: () => import('@dev-tools/app-status').then(m => m.AppStatusComponent),
			},
			{
				path: 'dev-demo-page',
				canActivate: [isDevGuard],
				loadComponent: () => import('@dev-tools/demo-page').then(m => m.DevDemoPageComponent),
			},
			{
				path: 'users',
				canActivate: [PermissionsGuard([PermissionType.users_manage], {fallbackRoute: 'home'})],
				loadChildren: () => import('@users/feature-users').then(m => m.featureUsersRoutes),
			},
			{
				path: 'user-edit/:userFormMode',
				loadChildren: () => import('@users/feature-user-edit').then(m => m.featureUserEditRoutes),
			},
			{
				path: 'user-edit/:userFormMode',
				outlet: GlobalDialoglService.GLOBAL_DIALOG_OUTLET_NAME,
				data: globalAppRouteConfigFactory({
					dialog: {inputsData: {userFormMode: 'create'}},
					permissions: [PermissionType.users_manage],
				}),
				loadChildren: () => import('@users/feature-user-edit').then(m => m.featureUserEditRoutes),
			},
			{
				path: 'company-settings',
				canActivate: [PermissionsGuard([PermissionType.users_manage], {fallbackRoute: 'home'})],
				loadChildren: () => import('@company-settings/company-settings').then(m => m.featureCompanySettingsRoutes),
			},
			{
				path: 'address-book',
				canActivate: [PermissionsGuard([PermissionType.permanent], {fallbackRoute: 'home'})],
				loadChildren: () => import('@users/feature-users-address-book').then(m => m.featureUsersAddressBookRoutes),
			},
			{
				path: 'groups',
				canActivate: [PermissionsGuard([PermissionType.permanent], {fallbackRoute: 'home'})],
				loadChildren: () => import('@users/feature-user-groups').then(m => m.featureUserGroupsRoutes),
			},
			{
				path: 'user-groups',
				canActivate: [PermissionsGuard([PermissionType.permanent, PermissionType.users_manage], {fallbackRoute: 'home'})],
				loadChildren: () => import('@users/feature-user-groups').then(m => m.featureUserGroupsRoutes),
			},
			{path: 'cycles_', loadChildren: () => import('@cycles/cycles-table').then(m => m.featureCyclesTableRoutes)},
			{
				path: 'cycles', loadChildren: () => import('@cycles/cycle-tables').then(m => m.featureCycleTablesRoutes)},
			{
				path: 'sent-mailing-list',
				loadChildren: () => import('@cycles/forms/sent-mailing-documents-list').then(m => m.featureSentMailingDocumentsListRoutes),
			},

			{
				path: 'app',
				loadChildren: () => import('@app-forms/feature-app-forms').then(m => m.featureAppFormsRoutes),
			},
			{
				path: 'app-reset/:redirectUrl/:afterForceReload',
				loadComponent: () => import('./app-reset/app-reset.component').then(m => m.AppResetComponent),
				data: globalAppRouteConfigFactory({autoHideSidenav: true}),
			},
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full',
			},
			{
				path: '**',
				redirectTo: 'login',
				pathMatch: 'full',
			},
		],
	},
];
