import {IFeatureStoresSchema} from '../types';
import {AppInfoStore} from '@cron/data-access';
import {AuthStore} from '@auth/data-access';
import {CycleSignStore} from '@cycles/sign/data-access';
import {FormGroupStore} from '@cycles/data-access';
import {GroupsStore, UsersStore} from '@users/data-access';
import {GlobalSearchStore} from '@app-layout/global-search/data-access';
import {CompanySettingsStore} from '@company-settings/data-access';
import {MetaTagsStore} from '@meta-tags/data-access';
import {FeatureStoreNames} from '@shared-types';
import appInfoInitialState = AppInfoStore.appInfoInitialState;
import authInitialState = AuthStore.authInitialState;
import cycleSignInitialState = CycleSignStore.cycleSignInitialState;
import formGroupInitialState = FormGroupStore.formGroupInitialState;
import usersInitialState = UsersStore.usersInitialState;
import globalSearchInitialState = GlobalSearchStore.globalSearchInitialState;
import companySettingsInitialState = CompanySettingsStore.companySettingsInitialState;
import metaTagsInitialState = MetaTagsStore.metaTagsInitialState;
import groupsInitialState = GroupsStore.groupsInitialState;

export const featureStoresInitialState: IFeatureStoresSchema = {
	[FeatureStoreNames.APP_INFO_FEATURE_KEY]: appInfoInitialState,
	[FeatureStoreNames.AUTH_FEATURE_KEY]: authInitialState,
	[FeatureStoreNames.CYCLE_SIGN_FEATURE_KEY]: cycleSignInitialState,
	[FeatureStoreNames.FORM_GROUP_FEATURE_KEY]: formGroupInitialState,
	[FeatureStoreNames.USERS_FEATURE_KEY]: usersInitialState,
	[FeatureStoreNames.GLOBAL_SEARCH_FEATURE_KEY]: globalSearchInitialState,
	[FeatureStoreNames.COMPANY_SETTINGS_FEATURE_KEY]: companySettingsInitialState,
	[FeatureStoreNames.META_TAGS_FEATURE_KEY]: metaTagsInitialState,
	[FeatureStoreNames.GROUPS_FEATURE_KEY]: groupsInitialState,
};
