import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, input, InputSignal, OnInit, signal, WritableSignal} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {SignalBinderDirective, SignalBinderModel} from '@shared/signals-extension';
import {ReportBugModel} from '../../data/models/report-bug.model';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {selectActiveUser} from '@auth/data-access';
import {UserModel} from '@users/data-access';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {ToastService} from '@utils';
import {ProgressBarModule} from 'primeng/progressbar';
import {BlockUIModule} from 'primeng/blockui';
import {NgTemplateOutlet} from '@angular/common';
import {CardModule} from 'primeng/card';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TooltipModule} from 'primeng/tooltip';
import {DialogControlService} from '@shared/dialog';
import {captureFeedback, captureUserFeedback} from '@sentry/angular';

type ReportBugSubmitStatus = 'NOT_SUBMITTED' | 'SENDING' | 'SUBMITTED' | 'ERROR';
export type ReportBugComponentInputs = {
	[key in keyof ReportBugComponent]: ReportBugComponent[key] extends InputSignal<infer T> ? T : never;
};

@Component({
	selector: 'dc-report-bug',
	standalone: true,
	imports: [MatButtonModule, MatInputModule, TranslateModule, SignalBinderDirective, ClipboardModule, ProgressBarModule, BlockUIModule, NgTemplateOutlet, CardModule, ProgressSpinnerModule, TooltipModule],
	templateUrl: './report-bug.component.html',
	styleUrl: './report-bug.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBugComponent implements OnInit {
	/* ------------------------------------------  Types and Constants ------------------------------------------ */

	/* ------------------------------------------ PROVIDERS / SERVICES ------------------------------------------ */
	protected readonly elementRef: ElementRef = inject(ElementRef);
	private readonly store: Store = inject(Store);
	private readonly toastService: ToastService = inject(ToastService);
	private readonly dialogService: DialogControlService = inject(DialogControlService);
	private cd: ChangeDetectorRef = inject(ChangeDetectorRef);
	/* ------------------------------------------------  Inputs ------------------------------------------------ */

	readonly error: InputSignal<Error> = input.required<Error>();
	readonly sentryTransactionID: InputSignal<string> = input.required<string>();
	/* ------------------------------------------------  Outputs ------------------------------------------------ */

	/* ------------------------------------------------  Signals ------------------------------------------------ */
	private activeUser$ = this.store.selectSignal(selectActiveUser);
	/* -------------------------------------------------- Data -------------------------------------------------- */
	protected readonly reportBug: SignalBinderModel<ReportBugModel> = new SignalBinderModel<ReportBugModel>(ReportBugModel, [], false);
	protected readonly submitStatus: WritableSignal<ReportBugSubmitStatus> = signal('NOT_SUBMITTED');
	/* ------------------------------------------------  Constructor ------------------------------------------------ */
	constructor() {}

	/* ----------------------------------------------- Lifecycle Hooks ----------------------------------------------- */
	ngOnInit(): void {
		// get active user - name and email address (if any)
		const activeUser = this.activeUser$();
		if (activeUser) {
			const activeUserModel: UserModel = new UserModel(activeUser);
			this.reportBug.patch({name: activeUserModel.displayName, email: activeUserModel.email});
		}
	}
	/* ------------------------------------------------  Methods ------------------------------------------------ */
	protected async onReportBug(): Promise<void> {
		try {
			if (this.sentryTransactionID()) {
				this.reportBug.patch({event_id: this.sentryTransactionID()});
				debugger;
				const report = this.reportBug.signal$();
				captureFeedback(report);

				this.submitStatus.set('SENDING');
				this.cd.detectChanges();
				setTimeout(() => {
					this.submitStatus.set('SUBMITTED');
					this.cd.detectChanges();
				}, 1000);
			}
		} catch (e) {
			this.submitStatus.set('ERROR');
			console.error(e);
			this.cd.detectChanges();
		}
	}

	protected onCopyTicket(): void {
		this.toastService.open({
			message: 'dc-error.report.ticket-copied-success',
			type: 'success',
			life: 2500,
			styleClass: 'text-center',
		});
	}

	protected onClose(): void {
		this.dialogService.close('ReportErrorDialog', {data: this.error});
	}
}
