{
	"IGNORE_ERRORS": [
		"Transaction timed out due to inactivity",
		"ExpressionChangedAfterItHasBeenCheckedError",
		"Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
		"Error: Transport destroyed",
		"The object can not be found here",
		"ASSERTION ERROR: NodeInjector must have some connection to the module injector tree",
		"this._cancelRendering is not a function",
		"Error: Cannot find a differ supporting object",
		"at genericl10n_GenericL10n.get"
	]
}
