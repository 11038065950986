import {ActionReducer} from '@ngrx/store';
import {Type} from '@angular/core';
import {FeatureStoreSchema} from './index';
import {BaseStoreConfig, isBaseStoreConfig} from '../../shared/base-store-config.type';

/**
	*  @description
	*/
export type FeatureStoreConfig<T extends FeatureStoreSchema> = BaseStoreConfig<T> & {
	effects: Type<any>[];
	reducer: ActionReducer<any>;
};

// guard:
export function isFeatureStoreConfig<T extends FeatureStoreSchema>(config: any): config is FeatureStoreConfig<T> {
	return isBaseStoreConfig(config) && Reflect.has(config, 'effects') && Reflect.has(config, 'reducer');
}
