import {EnvironmentProviders, Provider, Type} from '@angular/core';
import {provideEffects} from '@ngrx/effects';
import {ActionReducerMap, provideStore} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {isDevMode} from '@shared-types';
import {AppFeatureStores} from '../../index';
import {isFeatureStoreConfig} from '../../feature-stores/types/feature-store-config.type';
import {clearStoreMetaReducer} from './meta-reducers/clear-store.meta-reducer';
import {globalErrorMetaReducer} from './meta-reducers/global-error.meta-reducer';
import {FeatureStoreConfigs} from '../../feature-stores/configs';
import {idbPersistMetaReducer} from './meta-reducers/idb-persist.meta-reducer';
import featureStoreConfigs = FeatureStoreConfigs.featureStoreConfigs;
// Effects
const _effectsArray: Type<any>[] = Object.values(featureStoreConfigs).flatMap(config => {
	if (isFeatureStoreConfig(config)) {
		return config.effects;
	}
	return [];
}, []);

// Reducers
const _reducers: ActionReducerMap<AppFeatureStores.IFeatureStoresSchema> = Object.values(featureStoreConfigs).reduce((acc, config) => {
	if (isFeatureStoreConfig(config)) {
		acc[config.key] = config.reducer;
	}
	return acc;
}, {} as ActionReducerMap<AppFeatureStores.IFeatureStoresSchema>);

export const APP_STORE_PROVIDERS: Array<Provider | EnvironmentProviders> = [
	provideEffects(_effectsArray),
	provideStore(_reducers, {
		metaReducers: [idbPersistMetaReducer, clearStoreMetaReducer, globalErrorMetaReducer],
	}),
	provideStoreDevtools({
		logOnly: isDevMode(),
		maxAge: 25,
		connectInZone: true,
	}),
];
