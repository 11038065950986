import {APP_INITIALIZER, EnvironmentProviders, ErrorHandler, Injector, Provider} from '@angular/core';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {ConfirmationService, MessageService, PrimeNGConfig} from 'primeng/api';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiInterceptor} from '@auth/data-access';
import {LanguageService} from '@core/language';
import {DeviceService} from '@shared/device';
import {DialogControlService, GLOBAL_DIALOG_CONTROLLERS, GLOBAL_DIALOG_CONTROLLERS_Factory} from '@shared/dialog';
import {SyncerService, ToastService} from '@utils';
import {AppInitializer} from './app.initializer';
import {StorageService} from '@core/storage';
import {GlobalErrorHandler} from '@error-management/error-handler';
import {GlobalsService, RouteHistoryService} from '@global-data';
import {DialogService} from 'primeng/dynamicdialog';
import {TraceService} from '@sentry/angular';

const errorHandlingProviders: Array<Provider | EnvironmentProviders> = [
	{
		provide: ErrorHandler,
		useClass: GlobalErrorHandler,
		deps: [Injector, ToastService],
	},
];

const _providers: Array<Provider | EnvironmentProviders> = [
	// Don't put any providers before APP_INITIALIZER!
	{
		provide: APP_INITIALIZER,
		useFactory: AppInitializer,
		deps: [Injector, StorageService, LanguageService, DeviceService, PrimeNGConfig, SyncerService, RouteHistoryService, GlobalsService, TraceService],
		multi: true,
	},
	{
		provide: GLOBAL_DIALOG_CONTROLLERS,
		deps: [Injector],
		useFactory: GLOBAL_DIALOG_CONTROLLERS_Factory,
	},

	...errorHandlingProviders,
	{
		provide: HTTP_INTERCEPTORS,
		deps: [],
		useClass: ApiInterceptor,
		multi: true,
	},

	provideAnimationsAsync(),
];

const _appServices: Array<Provider | EnvironmentProviders> = [MessageService, DialogControlService, DialogService, ConfirmationService];

const AppProviders = [..._providers, ..._appServices];
export default AppProviders;
