import {FeatureStoreConfig} from '../../types/feature-store-config.type';
import {MetaTagsEffects, metaTagsReducer, MetaTagsStore} from '@meta-tags/data-access';
import {FeatureStoreNames} from '@shared-types';
import {featureStoresInitialState} from '../feature-stores.initial-state';
import IMetaTagsState = MetaTagsStore.IMetaTagsState;

export const metaTagsFeatureStoreConfig: FeatureStoreConfig<IMetaTagsState> = {
	key: FeatureStoreNames.META_TAGS_FEATURE_KEY,
	initialState: featureStoresInitialState[FeatureStoreNames.META_TAGS_FEATURE_KEY] as IMetaTagsState,
	effects: [MetaTagsEffects],
	reducer: metaTagsReducer,

	onAppInit(state: IMetaTagsState): IMetaTagsState {
		return state;
	},
	onLoginFn(state: IMetaTagsState): IMetaTagsState {
		return state;
	},
	onLogout(state: IMetaTagsState): IMetaTagsState {
		return this.initialState;
	},
};
