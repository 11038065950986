import {FeatureStoreConfig} from '../../types/feature-store-config.type';
import {AppInfoEffects, appInfoReducer, AppInfoStore} from '@cron/data-access';
import {FeatureStoreNames} from '@shared-types';
import {featureStoresInitialState} from '../feature-stores.initial-state';
import IAppInfoFeatureStore = AppInfoStore.IAppInfoFeatureStore;

export const appInfoFeatureStoreConfig: FeatureStoreConfig<IAppInfoFeatureStore> = {
	key: FeatureStoreNames.APP_INFO_FEATURE_KEY,
	initialState: featureStoresInitialState[FeatureStoreNames.APP_INFO_FEATURE_KEY] as IAppInfoFeatureStore,
	effects: [AppInfoEffects],
	reducer: appInfoReducer,

	onAppInit(state: IAppInfoFeatureStore): IAppInfoFeatureStore {
		return state;
	},
	onLoginFn(state: IAppInfoFeatureStore): IAppInfoFeatureStore {
		return state;
	},
	onLogout(state: IAppInfoFeatureStore): IAppInfoFeatureStore {
		return {
			...featureStoresInitialState[FeatureStoreNames.APP_INFO_FEATURE_KEY],
			indicators: state.indicators,
		} as IAppInfoFeatureStore;
	},
};
