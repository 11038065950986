import type {Primitive, SendFeedbackParams} from '@sentry/types';

export class ReportBugModel implements SendFeedbackParams {
	event_id: string;
	email: string;
	name: string;
	comments: string;

	constructor(init?: Partial<ReportBugModel>) {
		Object.assign(this, init);
	}

	message: string;
	url?: string;
	source?: string;
	associatedEventId?: string;
	tags?: {[key: string]: Primitive};
}
