import {FeatureStoreConfig} from '../../types/feature-store-config.type';
import {AllUsersEffects, MultiUsersEffects, PortraitEffects, TempUserEffects, UserEffects, usersReducer, UsersStore} from '@users/data-access';
import {FeatureStoreNames} from '@shared-types';
import {featureStoresInitialState} from '../feature-stores.initial-state';
import IUsersState = UsersStore.IUsersState;

export const usersFeatureStoreConfig: FeatureStoreConfig<IUsersState> = {
	key: FeatureStoreNames.USERS_FEATURE_KEY,
	initialState: featureStoresInitialState[FeatureStoreNames.USERS_FEATURE_KEY] as IUsersState,
	effects: [AllUsersEffects, UserEffects, TempUserEffects, MultiUsersEffects, PortraitEffects],
	reducer: usersReducer,

	onAppInit(state: IUsersState): IUsersState {
		return state;
	},
	onLoginFn(state: IUsersState): IUsersState {
		return state;
	},
	onLogout(state: IUsersState): IUsersState {
		return this.initialState;
	},
};
