import { IClearStoreGlobalAction, StoreGlobalActionType } from '@global-data';
import { Action, MetaReducer } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { FeatureStoreConfigs } from '../../../feature-stores/configs';
import { IndexedDbState } from '../../../index';
import { SignalStoresConfigs } from '../../../sync-signal-stores/configs';
import FeatureStoreConfigsStruct = FeatureStoreConfigs.FeatureStoreConfigsStruct;
import SignalStoreConfigsStruct = SignalStoresConfigs.SignalStoreConfigsStruct;
import signalStoresConfigs = SignalStoresConfigs.signalStoresConfigs;
import featureStoreConfigs = FeatureStoreConfigs.featureStoreConfigs;

export const clearStoreMetaReducer: MetaReducer<any> = (reducer: ActionReducer<any>): ActionReducer<IndexedDbState, Action & IClearStoreGlobalAction> => {
	const featureStoreConfiguratoins: FeatureStoreConfigsStruct = featureStoreConfigs;
	const signalStoreConfigurations: SignalStoreConfigsStruct = signalStoresConfigs;
	const allStoreConfigurations = {...featureStoreConfiguratoins, ...signalStoreConfigurations};

	return function (state, action) {
		if (action.type === StoreGlobalActionType.CLEAR_STORE) {
			const localStateSnapshot: IndexedDbState = window.APP_INDEXED_DB();
			const nextState = {...localStateSnapshot};

			// Feature stores clear
			if (action?.clearFeatureStores) {
				for (const key in featureStoreConfiguratoins) {
					const config = featureStoreConfiguratoins[key];
					if (config.onLogout) {
						nextState[key] = config.onLogout(nextState[key]);
					}
				}
			}

			// Signal stores clear
			if (action?.clearSignalStores) {
				for (const key in signalStoreConfigurations) {
					const config = signalStoreConfigurations[key];
					if (config?.onLogout) {
						nextState[key] = config.onLogout(nextState[key]);
					}
				}

				//	Clear all stores on payload
				if (action?.storesToClear) {
					for (const key in action.storesToClear) {
						const storeKey = action.storesToClear[key];
						const config = allStoreConfigurations[storeKey];
						if (config.onLogout) {
							// nextState[storeKey] = config.onLogout(nextState[storeKey]);
						}
					}
				}
				return nextState;
			}
		}

		return reducer(state, action);
	};
};
