import {ILivenessValidatorState, livenessValidatorInitialState} from '@validations/data-access';
import {SignalStoreNames} from '@shared-types';

export const livenessValidatorSignalStoreConfig = {
	key: SignalStoreNames.LIVENESS_VALIDATOR,
	initialState: livenessValidatorInitialState,

	onAppInit(state: ILivenessValidatorState): ILivenessValidatorState {
		return state;
	},
	onLoginFn(state: ILivenessValidatorState): ILivenessValidatorState {
		return state;
	},
	onLogout(state: ILivenessValidatorState): ILivenessValidatorState {
		return {
			...livenessValidatorInitialState,
		};
	},
};
