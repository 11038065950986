<div class="report-error-header">
	<span class="report-error-title">
		{{ 'dc-error.report.title' | translate }}
	</span>
	<img
		src="assets/icons/icon-72x72.png"
		alt="doccycle icon"
		class="doccycle-icon"
	/>
</div>

<p-blockUI
	#blockUI
	[target]="ReportContent"
	[blocked]="submitStatus() !== 'NOT_SUBMITTED'"
	class="z-[1111]"
/>

<p-card
	#ReportContent
	class="w-full"
>
	<div class="report-error-content">
		<!-- -------------------- Name -------------------- -->
		<mat-form-field
			appearance="outline"
			class="dense-min w-full"
		>
			<mat-label> {{ 'dc-error.report.name' | translate }}</mat-label>
			<input
				matInput
				required
				name="name"
				class="report-error-name"
				[SignalBinder]="[reportBug, 'name', [{type: 'required', errorMessage: 'dc-error.report.errors.required'}]]"
			/>
			<mat-error>*</mat-error>
		</mat-form-field>
		<!-- -------------------- Email -------------------- -->
		<mat-form-field
			appearance="outline"
			class="dense-min w-full"
		>
			<mat-label>
				{{ 'dc-error.report.email' | translate }}
			</mat-label>
			<input
				matInput
				required
				name="email"
				class="report-error-email"
				[SignalBinder]="[
					reportBug,
					'email',
					[
						{type: 'required', errorMessage: 'dc-error.report.errors.required'},
						{type: 'email', errorMessage: 'dc-error.report.errors.email'}
					]
				]"
			/>
			<mat-error>*</mat-error>
		</mat-form-field>
		<!-- -------------------- Message -------------------- -->
		<mat-form-field
			appearance="outline"
			class="dense-min w-full"
		>
			<mat-label>
				{{ 'dc-error.report.bug-details' | translate }}
			</mat-label>
			<textarea
				matInput
				required
				name="message"
				[rows]="6"
				maxlength="500"
				class="report-error-message"
				[SignalBinder]="[reportBug, 'comments', [{type: 'required', errorMessage: 'dc-error.report.errors.required'}]]"
			></textarea>
			<mat-error>*</mat-error>
		</mat-form-field>
	</div>
</p-card>

@switch (submitStatus()) {
	@case ('NOT_SUBMITTED') {
		<div class="report-error-actions">
			<button
				type="submit"
				class="send"
				[disabled]="!reportBug.isValid()"
				mat-raised-button
				color="warn"
				(click)="onReportBug()"
			>
				{{ 'dc-error.report.buttons.submit-report' | translate }}
			</button>
			<button
				type="button"
				class="cancel"
				mat-stroked-button
				color="basic"
				(click)="onClose()"
			>
				{{ 'dc-error.report.buttons.cancel' | translate }}
			</button>
		</div>
	}
	@case ('SENDING') {
		<p-progressBar
			mode="indeterminate"
			class="w-full h-2"
		/>
	}
	@case ('SUBMITTED') {
		<div class="report-error-success">
			<div class="report-error-success-message">
				{{ 'dc-error.report.submission-status-msg' | translate: {ticket: sentryTransactionID()} }}
			</div>

			<div class="report-error-success-actions">
				<button
					type="button"
					class="copy dense-min"
					mat-stroked-button
					color="info"
					[cdkCopyToClipboard]="sentryTransactionID()"
					[cdkCopyToClipboardAttempts]="1"
					(cdkCopyToClipboardCopied)="onCopyTicket()"
					[pTooltip]="'dc-error.report.copy-to-clipboard' | translate"
					tooltipStyleClass="dc-tooltip dc-gray-tooltip"
					[tooltipOptions]="{tooltipPosition: 'top', positionTop: -5}"
				>
					<i class="fa-duotone fa-copy"></i>
				</button>
				<button
					type="button"
					class="cancel"
					mat-stroked-button
					color="basic"
					(click)="onClose()"
				>
					{{ 'dc-error.report.buttons.close' | translate }}
				</button>
			</div>
		</div>
	}
	@case ('ERROR') {
		<div class="report-error-error">
			<div class="report-error-error-message">
				{{ 'dc-error.report.submission-error-msg' | translate }}
			</div>
		</div>
	}
}
