import { INIT, MetaReducer, UPDATE, Action } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import {IndexedDbSyncService} from '@global-data';
import {patchState} from '@ngrx/signals';

const INIT_ACTION = INIT;
const UPDATE_ACTION = UPDATE;

export const idbPersistMetaReducer: MetaReducer<any> = (reducer: ActionReducer<any>): ActionReducer<any> => {
	const idbSyncService = IndexedDbSyncService.getInstance();
	return function (state, action) {
		let nextState = reducer(state, action);
		if (action.type === INIT_ACTION && !state) {
			if (window.APP_INDEXED_DB_LOADED) {
				nextState = window.APP_STORE_SNAPSHOT();
				window.APP_INDEXED_DB_LOADED = false;
			}
		} else {
			// TODO
		}

		idbSyncService.bulkSet(nextState).then(() => {
			patchState(window.APP_INDEXED_DB, nextState);
		});
		return nextState;
	};
};
