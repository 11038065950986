import {routeRedirector} from './route-redirector/route-redirector';
import {loadIndexedDbSnapshot} from './load-indexed-db';

/**
 * @description Function that runs before the application bootstraps
 * Checks:
 * 1 - Checks the URL and redirects if necessary
 * 2 - Ensures that the indexedDB initialization is complete
 */
export async function beforeAppBootstrap(): Promise<boolean> {
	/* -------------------------------------------------------------------------------------------------------------- */
	const redirectorCheckPassed: boolean = routeRedirector();
	if (redirectorCheckPassed) {
		await loadIndexedDbSnapshot();
		return true;
	}

	return false;
}
