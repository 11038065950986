import {FeatureStoreConfig} from '../../types/feature-store-config.type';
import {globalSearchReducer, GlobalSearchStore} from '@app-layout/global-search/data-access';
import {FeatureStoreNames} from '@shared-types';
import {featureStoresInitialState} from '../feature-stores.initial-state';
import IGlobalSearchState = GlobalSearchStore.IGlobalSearchState;

export const globalSearchFeatureStoreConfig: FeatureStoreConfig<IGlobalSearchState> = {
	key: FeatureStoreNames.GLOBAL_SEARCH_FEATURE_KEY,
	initialState: featureStoresInitialState[FeatureStoreNames.GLOBAL_SEARCH_FEATURE_KEY] as IGlobalSearchState,
	effects: [],
	reducer: globalSearchReducer,

	onAppInit(state: IGlobalSearchState): IGlobalSearchState {
		return state;
	},
	onLoginFn(state: IGlobalSearchState): IGlobalSearchState {
		return state;
	},
	onLogout(state: IGlobalSearchState): IGlobalSearchState {
		return this.initialState;
	},
};

