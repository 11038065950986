import { SignalStoreNames } from '@shared-types';
import { AnyStoreTypeSchema, BaseStoreConfig } from '../../shared/base-store-config.type';
import { AppSyncSignalStoreSchema } from '../types';
import * as CONFIGS from './signal-store-configurations';

export namespace SignalStoresConfigs {
	export type SignalStoreConfigsStruct<SignalStoreName extends keyof AppSyncSignalStoreSchema = keyof AppSyncSignalStoreSchema> = Partial<
		Record<SignalStoreName, BaseStoreConfig<Extract<AppSyncSignalStoreSchema[SignalStoreName], AnyStoreTypeSchema>>>
	>;

	export const signalStoresConfigs: SignalStoreConfigsStruct = {
		// Use type assertion to bypass the type check
		[SignalStoreNames.LIVENESS_VALIDATOR as keyof AppSyncSignalStoreSchema]: undefined,
	};

	Object.keys(CONFIGS).forEach(configKey => {
		const config = CONFIGS[configKey];
		if (config.key in signalStoresConfigs) {
			signalStoresConfigs[config.key] = config;
		}
	});
}
