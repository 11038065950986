import {FeatureStoreConfig} from '../../types/feature-store-config.type';
import {CycleSignEffects, cycleSignReducer, CycleSignStore} from '@cycles/sign/data-access';
import {FeatureStoreNames} from '@shared-types';
import {featureStoresInitialState} from '../feature-stores.initial-state';
import ICycleSignState = CycleSignStore.ICycleSignState;

export const cycleSignFeatureStoreConfig: FeatureStoreConfig<ICycleSignState> = {
	key: FeatureStoreNames.CYCLE_SIGN_FEATURE_KEY,
	initialState: featureStoresInitialState[FeatureStoreNames.CYCLE_SIGN_FEATURE_KEY] as ICycleSignState,
	effects: [CycleSignEffects],
	reducer: cycleSignReducer,

	onAppInit(state: ICycleSignState): ICycleSignState {
		return state;
	},
	onLoginFn(state: ICycleSignState): ICycleSignState {
		return state;
	},
	onLogout(state: ICycleSignState): ICycleSignState {
		return state;
	},
};
