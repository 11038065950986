import {ImportProvidersSource} from '@angular/core';
import {NgxUiLoaderModule, NgxUiLoaderRouterModule} from 'ngx-ui-loader';
import {MatDialogModule} from '@angular/material/dialog';
import {Loader} from '@utils';
import GLOBAL_LOADER_ID = Loader.GLOBAL_LOADER_ID;

export const ExternalModules: ImportProvidersSource[] = [
	// Angular Material Dialog:
	MatDialogModule,
	// Ngx Ui Loader:
	NgxUiLoaderModule.forRoot({
		maxTime: 5000,
	}),
	NgxUiLoaderRouterModule.forRoot({
		loaderId: GLOBAL_LOADER_ID,
	}),
];
