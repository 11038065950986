// הגדרת טיפוס לקונפיגורציה של סטור
import {AppFeatureStores} from '../feature-stores';
import {AppSyncSignalStoreSchema} from '../sync-signal-stores/types';
import FeatureStoreSchema = AppFeatureStores.FeatureStoreSchema;
import {FeatureStoreNames, SignalStoreNames} from '@shared-types';

export type AnyStoreTypeSchema = AppSyncSignalStoreSchema | FeatureStoreSchema;

export type BaseStoreConfig<T extends AnyStoreTypeSchema> = {
	key: T extends AppSyncSignalStoreSchema ? SignalStoreNames : FeatureStoreNames;
	initialState: T;
	onAppInit?: (state: T) => T;
	onLoginFn?: (state: T) => T;
	onLogout?: (state: T) => T;
};
// guard:
export function isBaseStoreConfig<T extends AnyStoreTypeSchema>(config: any): config is BaseStoreConfig<T> {
	return config.key && config.initialState;
}
