import localforage from 'localforage';
import {FEATYRE_STORE_KEYS, GLOBAL_STORE_INITIAL_STATE, IndexedDbState, SIGNAL_STORE_KEYS} from '@app-store-management';
import {patchState, signalState} from '@ngrx/signals';
import {computed} from '@angular/core';
import {pick} from '@utils';
import {config} from '@cron/data-access';
import {StorageKeys} from '@core/storage';
import {DEFAULT_INDEXED_DB_NAME} from '@shared-types';

localforage.config({
	driver: localforage.INDEXEDDB,
	name: DEFAULT_INDEXED_DB_NAME,
	storeName: 'doccycle-store',
	version: config.indexedDB.stable_version,
});

window.localforage = localforage;
window.APP_INDEXED_DB_LOADED = false;
window.APP_INDEXED_DB = signalState<IndexedDbState>(null);
window.APP_STORE_SNAPSHOT = computed(() => {
	if (!window.APP_INDEXED_DB_LOADED) return;
	return pick(window.APP_INDEXED_DB(), FEATYRE_STORE_KEYS);
});
window.SIGNAL_STORE_SNAPSHOT = computed(() => {
	if (!window.APP_INDEXED_DB_LOADED) return;
	return pick(window.APP_INDEXED_DB(), SIGNAL_STORE_KEYS);
});

/**
 * @description Load the indexedDB snapshot into the app store (before app bootstrap)
 */
export async function loadIndexedDbSnapshot(): Promise<void> {
	const globalStoreInitialState = GLOBAL_STORE_INITIAL_STATE;
	const snapshot: IndexedDbState = {} as IndexedDbState;

	// Check if the indexedDB version is up-to-date
	const actualIndexedDBConfig = localforage.config();
	const localIndexedDBVersion: number = Number(localStorage.getItem(StorageKeys.GlobalKeys.INDEXED_DB_VERSION));
	if (actualIndexedDBConfig?.version !== localIndexedDBVersion) {
		// get all databases in	the indexedDB
		const databases = await window.indexedDB.databases();
		const old_store_names = config.indexedDB.old_store_names;
		// delete all databases
		for (const db of databases) {
			if (old_store_names.includes(db.name)) window.indexedDB.deleteDatabase(db.name);
		}

		localStorage.setItem(StorageKeys.GlobalKeys.INDEXED_DB_VERSION, actualIndexedDBConfig.version.toString());
	}

	for (const key of Object.keys(globalStoreInitialState)) {
		const idbSlice = await localforage.getItem(key);
		const initialSlice = globalStoreInitialState[key];
		snapshot[key] = Object.assign({}, initialSlice, idbSlice);
	}

	patchState(window.APP_INDEXED_DB, snapshot);
	window.APP_INDEXED_DB_LOADED = true;
}
