import {StorageKeys} from '@core/storage';
import {isDevMode} from '@shared-types';
import {SignalStoreManagerService} from '@shared/signal-store';
import clc from 'console-log-colors';
import {initSentryConfig} from './sentry.initializer';

export const initAppConfigurations = async (productionMode: boolean) => {
	localStorage.setItem(StorageKeys.GlobalKeys.ENVIRONMENT, productionMode ? 'production' : 'development');
	initLoggingConfig();
	await initSentryConfig();
	await SignalStoreManagerService.getInstance()?.loadAllSignalStoreIdbTables();
};

const initLoggingConfig = () => {
	if (isDevMode()) {
		console.log(clc.green('------------------ Development mode ------------------'));
	} else {
		console.log(clc.red('------------------ Production mode ------------------'));
		// in production enable logs only if StorageKeys.Dev.DEV_MODE is true in local storage
		const devMode = localStorage.getItem(StorageKeys.Dev.DEV_MODE);
		if (devMode === 'true' || isDevMode()) {
			console.log(clc.green('------------------ Development mode ------------------'));
		} else {
			console.log(clc.red('------------------ Production mode ------------------'));
			console.log = () => {};
			console.debug = () => {};
			console.warn = () => {};
			console.error = () => {};
		}
	}
};
