import {FeatureStoreConfig} from '../../types/feature-store-config.type';
import {CompanySettingsEffects, companySettingsReducer, CompanySettingsStore} from '@company-settings/data-access';
import {FeatureStoreNames} from '@shared-types';
import {featureStoresInitialState} from '../feature-stores.initial-state';
import ICompanySettingsState = CompanySettingsStore.ICompanySettingsState;

export const companySettingsFeatureStoreConfig: FeatureStoreConfig<ICompanySettingsState> = {
	key: FeatureStoreNames.COMPANY_SETTINGS_FEATURE_KEY,
	initialState: featureStoresInitialState[FeatureStoreNames.COMPANY_SETTINGS_FEATURE_KEY] as ICompanySettingsState,
	effects: [CompanySettingsEffects],
	reducer: companySettingsReducer,

	onAppInit(state: ICompanySettingsState): ICompanySettingsState {
		return state;
	},
	onLoginFn(state: ICompanySettingsState): ICompanySettingsState {
		return state;
	},
	onLogout(state: ICompanySettingsState): ICompanySettingsState {
		return this.initialState;
	},
};

export default companySettingsFeatureStoreConfig;