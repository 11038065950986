import {IFeatureStoresSchema} from './feature-stores/types';
import {featureStoresInitialState} from './feature-stores/configs';
import {AppFeatureStores as AppFeatureStoresNS} from './feature-stores';
import {Shared as SharedNS} from './shared';

import {SignalStores as SignalStoresNS} from './sync-signal-stores';

/* ------------------------------------------- APP FEATURE STORES ------------------------------------------- */
export import AppFeatureStores = AppFeatureStoresNS;
/* ------------------------------------------- SIGNAL STORES (with Local Sync) ------------------------------------------- */
export import SignalStores = SignalStoresNS;
/* ----------------------------------------------- SHARED ----------------------------------------------- */
export import Shared = SharedNS;

/* ----------------------------------------------- INDEXED DB ----------------------------------------------- */
export type IndexedDbState = IFeatureStoresSchema & SignalStoresNS.IAppSyncSignalStoresSchema;

// Global Store Initial State
export const GLOBAL_STORE_INITIAL_STATE: IndexedDbState = {
	...featureStoresInitialState,
	...SignalStoresNS.signalStoresInitialState,
};

// Global Store Keys
export const FEATYRE_STORE_KEYS = Object.keys(featureStoresInitialState);
export const SIGNAL_STORE_KEYS = Object.keys(SignalStoresNS.signalStoresInitialState);

// Global Store Configs
export const GLOBAL_STORE_CONFIGS = {
	...AppFeatureStoresNS.FeatureStoreConfigs.featureStoreConfigs,
	...SignalStoresNS.SignalStoresConfigs.signalStoresConfigs,
};
