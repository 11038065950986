import {FeatureStoreConfig} from '../../types/feature-store-config.type';
import {FeatureStoreNames} from '@shared-types';
import {featureStoresInitialState} from '../feature-stores.initial-state';
import {ActiveUserDetailsEffects, ActiveUserPortraitEffects, ActiveUserStampsEffects, AuthEffects, authReducer, AuthStore} from '@auth/data-access';
import IAuthState = AuthStore.IAuthState;

export const authFeatureStoreConfig: FeatureStoreConfig<IAuthState> = {
	key: FeatureStoreNames.AUTH_FEATURE_KEY,
	initialState: featureStoresInitialState[FeatureStoreNames.AUTH_FEATURE_KEY] as IAuthState,
	effects: [AuthEffects, ActiveUserDetailsEffects, ActiveUserStampsEffects, ActiveUserPortraitEffects],
	reducer: authReducer,

	onAppInit(state: IAuthState): IAuthState {
		return state;
	},
	onLoginFn(state: IAuthState): IAuthState {
		return state;
	},
	onLogout(state: IAuthState): IAuthState {
		return {
			...featureStoresInitialState[FeatureStoreNames.AUTH_FEATURE_KEY],
		} as IAuthState;
	},
};

