import {BrowserOptions, browserTracingIntegration, dedupeIntegration, functionToStringIntegration, httpClientIntegration, init, makeBrowserOfflineTransport, makeFetchTransport, Scope} from '@sentry/angular';
import {isDevMode} from '@angular/core';
import {environment} from '@shared-types';
import {config} from '@cron/data-access';
import {StorageKeys} from '@core/storage';
import {storageSignal} from '@shared/signals-extension';

const allowDebuggingSentry$ = storageSignal(StorageKeys.Logging.SENTRY_DEBUG, false);

export const initSentryConfig = async () => {
	const sentryConfig: BrowserOptions = {
		dsn: 'https://763d021eb71715a6e6ec33cf4da5f2e6@o1077959.ingest.us.sentry.io/4507729691738112',
		integrations: [dedupeIntegration, functionToStringIntegration, httpClientIntegration],
		debug: isDevMode() && allowDebuggingSentry$(),
		release: config.version,
		environment: `${isDevMode() ? `local-${environment.name}` : environment.name}`,
		maxValueLength: 150,
		sendDefaultPii: true,
	};
	/* ------------------------------------------ Add Sentry Features: ------------------------------------------ */
	addEnvironmentTags(sentryConfig);
	// Sentry Tracing:
	useTracing(sentryConfig);
	// Sentry Offline Transport:
	useOfflineTransport(sentryConfig);

	init(sentryConfig);
	return Promise.resolve();
};
/* -------------------------------------------------------------------------------------------------------------- */
const addEnvironmentTags = (sentryConfig: BrowserOptions) => {
	sentryConfig.initialScope = (scope: Scope) => {
		scope.setTag('environment', sentryConfig.environment);
		scope.setTag('sw:version', localStorage.getItem(StorageKeys.GlobalKeys.SW_VERSION) || 'N/A');
		scope.setTag('session_start', new Date().toISOString());
		return scope;
	};
};

const useTracing = (sentryConfig: BrowserOptions) => {
	sentryConfig.integrations = integrations => {
		integrations.push(browserTracingIntegration());
		return integrations;
	};
	sentryConfig.tracesSampleRate = isDevMode() ? 1.0 : 0.6;
	sentryConfig.tracePropagationTargets = ['localhost', '*'];
};

const useOfflineTransport = (sentryConfig: BrowserOptions) => {
	sentryConfig.transport = makeBrowserOfflineTransport(makeFetchTransport);
	sentryConfig.transportOptions = {
		bufferSize: 100,
	};
};
