import * as SignalStoreConfigsDir from './configs';
import * as SignalStoreTypesDir from './types';

export namespace SignalStores {
	// Types:
	export import IAppSyncSignalStoresSchema = SignalStoreTypesDir.IAppSyncSignalStoresSchema;
	export import AppSyncSignalStoreSchema = SignalStoreTypesDir.AppSyncSignalStoreSchema;
	// Configs:
	export import SignalStoresConfigs = SignalStoreConfigsDir.SignalStoresConfigs;
	export import signalStoresInitialState = SignalStoreConfigsDir.signalStoresInitialState;
}
