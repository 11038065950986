@if (sidenavService.isSidenavAvailable()) {
	<aside>
		@defer (when sidenavService.isSidenavAvailable()) {
			@if (sidenavService.isSidenavOpen()) {
				<p-sidebar
					styleClass="APP-SIDE-NAVBAR"
					[visible]="sidenavService.isSidenavOpen()"
					[showCloseIcon]="false"
					[modal]="false"
					[position]="sidenavService.sideNavPosition()"
				>
					<dc-sidenav>
						<ng-container
							sidenav-toggle-button
							*ngTemplateOutlet="ToggleButton"
						/>
					</dc-sidenav>
				</p-sidebar>
			} @else {
				<span class="block pt-2">
					<ng-container *ngTemplateOutlet="ToggleButton" />
				</span>
			}

			<ng-template #ToggleButton>
				<button
					class="transparent-button p-0"
					#HideSideNavbarBtn
					[pTooltip]="sidenavService.sideNavToggleBtnLabel() | translate"
					tooltipStyleClass="dc-tooltip dc-gray-tooltip"
					(click)="sidenavService.toggle()"
				>
					<i [class]="sidenavService.sideNavToggleBtnIcon()"></i>
				</button>
			</ng-template>
		} @error {
			P-SIDENAV NOT AVAILABLE
		}
	</aside>
}
<main
	id="APP-CONTENT"
	class="APP-CONTENT dc-scrollbar"
	dc-dir
	#dcDir="dcDir"
	[dir]="dcDir.currentDir()"
>
	<router-outlet />
</main>

<!--// APP_TOAST-->
<p-toast
	key="APP_TOAST"
	#AppToast
>
	<ng-template
		pTemplate="message"
		let-message
	>
		<main class="APP_TOAST">
			@if (message.icon) {
				<div class="APP_TOAST__ICON">
					<i [class]="message.icon"></i>
				</div>
			}

			<div class="APP_TOAST__CONTENT">
				<div class="APP_TOAST__TITLE">
					<span>{{ message.summary }}</span>
				</div>
				@if (message?.detail; as subMessage) {
					<div class="APP_TOAST__MESSAGE">
						{{ subMessage }}
					</div>
				}
			</div>

			@if (message?.actionButton; as actionButton) {
				<div class="APP_TOAST__ACTION_BUTTON">
					<button (click)="toastService.onClickAction(AppToast, message)">
						<i [class]="actionButton.icon"></i>
						{{ actionButton.label }}
					</button>
				</div>
			}
		</main>
	</ng-template>
</p-toast>

<!-- ---------------------------------------------  Global Loader --------------------------------------------- -->
@if (loaderService.loaderConfigurations(); as loaderConfig) {
	<ngx-ui-loader
		#GLOBAL_LOADER
		[loaderId]="GLOBAL_LOADER_ID"
		[fgsColor]="loaderConfig.fgsColor"
		[fgsPosition]="loaderConfig.fgsPosition"
		[fgsSize]="loaderConfig.fgsSize"
		[fgsType]="loaderConfig.fgsType"
		[bgsColor]="loaderConfig.bgsColor"
		[bgsOpacity]="loaderConfig.bgsOpacity"
		[bgsPosition]="loaderConfig.bgsPosition"
		[bgsSize]="loaderConfig.bgsSize"
		[bgsType]="loaderConfig.bgsType"
		[gap]="loaderConfig.gap"
		[logoPosition]="loaderConfig.logoPosition"
		[logoSize]="loaderConfig.logoSize"
		[logoUrl]="loaderConfig.logoUrl"
		[overlayColor]="loaderConfig.overlayColor"
		[pbColor]="loaderConfig.pbColor"
		[pbDirection]="loaderConfig.pbDirection"
		[pbThickness]="loaderConfig.pbThickness"
		[hasProgressBar]="loaderConfig.hasProgressBar"
		[text]="loaderConfig.text"
		[textColor]="loaderConfig.textColor"
		[textPosition]="loaderConfig.textPosition"
		[ngClass]="loaderConfig.styleClass"
	/>
}

<ng-template
	DcDialog="ReportErrorDialog"
	height="max-content"
	width="15%"
	[hasbackdrop]="true"
	[closeonnavigation]="true"
	containerClass="overflow-hidden"
	[mobileDialogDims]="['max-content', 'max-content', '100%', '100%']"
	[ngAs]="ReportBugComponentInputs"
	let-data
>
	@defer {
		<dc-report-bug
			[error]="data.error"
			[sentryTransactionID]="data.sentryTransactionID"
		/>
	}
</ng-template>

<!-- ---------------------------------------------  Global Dialog --------------------------------------------- -->
<p-dialog
	#GlobalDialogREF
	[DomReferer]="{
		ref: GlobalDialogREF,
		fn: globalDialogControlService.__setGlobalDialogRef
	}"
	[visible]="globalDialogControlService.visible$()"
	(visibleChange)="globalDialogControlService.close()"
>
	<router-outlet [name]="GlobalDialogControlService.GLOBAL_DIALOG_OUTLET_NAME" />
</p-dialog>

