import * as Types from './types';
import * as Configs from './configs';

export namespace AppFeatureStores {
	// Types:
	export import IFeatureStoresSchema = Types.IFeatureStoresSchema;
	export import FeatureStoreSchema = Types.FeatureStoreSchema;
	// Configs:
	export import FeatureStoreConfigs = Configs.FeatureStoreConfigs;
	export import featureStoresInitialState = Configs.featureStoresInitialState;
}
